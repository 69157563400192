<template>
  <b-container fluid>

    <Dash
      url="conciliations_soulpay"
      :params="{
        start_date: startDate,
        end_date: endDate
      }"
    />
  </b-container>
</template>

<script>
import Dash from '../reports/components/Dash.vue';

export default {
    components: {
        Dash,
    },

    props: {
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
    },
};
</script>
